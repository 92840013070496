import React from 'react'
import { Helmet } from 'react-helmet'
import { PageProps, graphql } from 'gatsby'
import Layout from '../components/layout'

interface DataType {
  markdownRemark: {
    html: string
    frontmatter: {
      title: string
      description: string
    }
  }
}

type PostPageProps = PageProps<DataType> | Record<string, never>

export default function PostTemplate({
  data,
  location
}: PostPageProps): JSX.Element {
  const { markdownRemark: post } = data
  const title = post.frontmatter.title
  const description = post.frontmatter.description
  const content = post.html
  return (
    <Layout location={location}>
      <Helmet title={title}>
        <meta name="description" content={description} />
      </Helmet>
      <section className="section">
        <h1 className="title is-size-2">{title}</h1>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
